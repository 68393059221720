<template>
    <div class="Home">
        <page-header/>
        <div class="uk-section" style="margin-bottom: 60px;">
            <div style="min-height: 500px;" class="uk-container" uk-gird>

                <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">{{$t("userRegister")}}</h3>
                    <!--<p>Та excel ээр хэрэглэгч бүртгэх боломжтой </p>-->
                    <hr>
                    <div v-if="isError" class="uk-alert-danger" uk-alert>
                        <a class="uk-alert-close" uk-close></a>
                        <p>{{errmsg}}</p>
                    </div>
                    <ul uk-accordion="multiple: true">
                        <li class="uk-open">
                            <a class="uk-accordion-title" href="#">{{$t("eUserUpload")}}</a>
                            <div class="uk-accordion-content">
                                <div class="uk-padding" style="position: relative">
                                    <input type="file" class="dragDrop" @change="previewFileExcel($event)" name="file"
                                           accept=".xlsx">
                                    <div class="js-upload uk-placeholder uk-text-center">
                                        <span uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>
                                        <span class="uk-text-middle"
                                              v-if="excel.fileName.length > 0">{{excel.fileName}}</span>
                                        <span class="uk-text-middle" v-else>{{$t("chEfile")}}</span>
                                        <div uk-form-custom>
                                              <span class="uk-link uk-margin-small-left">
                                                  {{$t("chFile")}}
                                              </span>
                                        </div>
                                    </div>
                                    <div class="uk-flex uk-flex-center uk-flex-middle">
                                        <!--<img-->
                                        <!--src="../../assets/excel.png"-->
                                        <!--alt=""  width="60px">-->
<!--                                        <a @click="downUserTemp" href="javascript:void(0)">-->
<!--                                            <h4 class="uk-margin-remove">-->
<!--                                                {{$t("orgaQuestionList.download")}}</h4></a>-->
                                      <div class="uk-flex uk-flex-center uk-padding-small" @click="downUserTemp">
                                        <div class="uk-flex uk-flex-left">
                                          <img src="../../assets/excel.png" alt="" width="60px">
                                          <a href="javascript:void(0)" download="excelUserTemplate.xlsx">
                                            <h4 class="uk-margin-remove uk-padding-small">
                                              {{$t("orgaQuestionList.download")}}</h4></a>
                                        </div>
                                      </div>
                                    </div>
                                    <!--<div class="uk-alert-primary uk-margin" uk-alert>-->
                                    <!--<a class="uk-alert-close" uk-close></a>-->
                                    <!--<h4 class="uk-margin-remove" style="margin-top: 15px !important;">Attention</h4>-->
                                    <!--<p class="uk-margin-small">*Багш эсвэл Сурагч эсэх (student/teacher)</p>-->
                                    <!--<p class="uk-margin-small">*Нэвтрэх ID давхардахгүй байх</p>-->
                                    <!--<p class="uk-margin-small">*Нууц үг 4өөс дээш урттай байх</p>-->
                                    <!--<p class="uk-margin-small">*Хэрэглэгчийн нэрийг заавал оруулах</p>-->
                                    <!--</div>-->
                                    <button type="button" @click="createExcelUsers()" style="float: right"
                                            class="uk-button uk-button-primary">
                                        {{$t("eupload")}}
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li class="uk-open">
                            <a class="uk-accordion-title" href="#">{{$t("organization.questionList.imageUpload")}}</a>
                            <div class="uk-accordion-content">
                                <div class="uk-padding" style="position: relative">
                                    <input type="file" class="dragDrop" @change="previewFileZip($event)" name="file"
                                           accept=".zip">
                                    <div class="js-upload uk-placeholder uk-text-center">
                                        <span uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>
                                        <span class="uk-text-middle" v-if="zip.fileName.length > 0">{{zip.fileName}}
                                      </span>
                                        <span class="uk-text-middle" v-else>{{$t("chZfile")}}</span>
                                        <div uk-form-custom>
                                            <span class="uk-link uk-margin-small-left">{{$t("chFile")}}</span>
                                        </div>
                                    </div>
                                    <button type="button" @click="uploadZip()" style="float: right"
                                            class="uk-button uk-button-primary">
                                        {{$t("zupload")}}
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <page-footer/>
    </div>
</template>
<script>
    import AuthService from "../../services/AuthenticationService";
    import {mapGetters} from 'vuex'
    import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

    const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

    export default {
        name: 'app',
        components: {
            PageHeader,
            PageFooter
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'currentUser'])
        },
        data() {
            return {
                excel: {
                    excelFile: null,
                    fileName: "",
                },
                zip: {
                    zipFile: null,
                    fileName: "",
                },
                isError: false,
                errmsg: ''
            };
        },
        mounted() {
        },
        methods: {
            downUserTemp() {
                window.location.href = "/uploadingDir/ubtcloud_user_template3.xlsx?v=" + Date.now();
            },
            errshow(msg) {
                this.isError = true;
                this.errmsg = msg
            },
            async createExcelUsers() {
                try {
                    let formData = new FormData();
                    if (this.excel.fileName == '') {
                        alert("choose excel file");
                        return;
                    }
                    this.isError = false;

                    formData.append('efile', this.excel.excelFile);
                    formData.append('orgid', this.currentUser.organizs[0].organizId);
                    const response = await AuthService.registerExcelUser(formData);
                    if (response.data.status === 200) {
                        alert("Success");
                        window.location.reload()
                    } else {
                        this.errshow(response.data.message)
                    }
                } catch (e) {
                    console.log(e);
                    this.errshow(e.response.data.message)
                }
            },
            async uploadZip() {
                try {
                    let formData = new FormData();
                    if (this.zip.fileName == '') {
                        alert("choose zip file");
                        return;
                    }
                    this.isError = false;

                    formData.append('zfile', this.zip.zipFile);
                    const response = await AuthService.uploadImages(formData);
                    if (response.data.status === 200) {
                        alert("Success");
                        window.location.reload()
                    } else {
                        this.errshow(response.data.message)
                    }
                } catch (e) {
                    console.log(e);
                    this.errshow(e.response.data.message)

                }
            },
            previewFileExcel(event) {
                this.excel.excelFile = event.target.files[0]
                this.excel.fileName = event.target.files[0].name

                if (this.excel.excelFile) {
                    if (/\.(XLSX)$/i.test(this.excel.fileName)) {
                        console.log("nice")
                    } else {
                        alert("File format is incorrect. Please check your file!");
                        this.excel.fileName = ''
                        this.excel.excelFile = ''
                        return
                    }
                }
            },
            previewFileZip(event) {
                if (event.target.files[0]['type'] == 'application/x-zip-compressed') {
                    this.zip.zipFile = event.target.files[0];
                    this.zip.fileName = event.target.files[0].name
                } else {
                    alert("Please choose zip file")
                }
            },

        }
    }
</script>
<style scoped>
    .dragDrop {
        width: 100%;
        height: 80px;
        align-items: center;
        text-align: center;
        opacity: 0;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 64px;
    }
</style>
